import {
  AiOutlineInstagram,
  AiOutlineGithub,
  AiOutlineMail,
} from 'react-icons/ai'
import { CiLinkedin } from 'react-icons/ci'

export const socialsSet = [
  {
    id: 1,
    title: 'Instagram',
    href: 'https://www.instagram.com/fiqri71194/',
    icon: <AiOutlineInstagram />,
  },
  {
    id: 2,
    title: 'Gmail',
    href: 'mailto:muh.fikri.ananda@gmail.com',
    icon: <AiOutlineMail />,
  },
  {
    id: 3,
    title: 'Linkedin',
    href: 'https://www.linkedin.com/in/m-fiqri-ananda-994965147/',
    icon: <CiLinkedin />,
  },
  {
    id: 4,
    title: 'Github',
    href: 'https://github.com/MFiqri17',
    icon: <AiOutlineGithub />,
  },
]


