import { Link } from 'react-scroll'

export const Button1 = ({ link, title, className, shadowClass }) => {
  return (
    <Link
      smooth
      spy
      offset={-50}
      className={`group lg:w-[201px] md:w-[190px] sm:w-[300px] md:mt-0 -mt-5 text-center   ${className}`}
      to={link}
    >
      <div
        className={`relative ${shadowClass} md:top-16 top-14 z-0 md:h-[59px] h-[49px] md:w-full w-[170px] border-2 border-black bg-transparent text-transparent opacity-0 group-hover:opacity-100`}
      >
        {title}
      </div>
      <button className="z-10 md:text-xl text-base font-amiri font-bold md:h-[59px] h-[49px] md:w-full w-[170px] bg-white group-hover:bg-black group-hover:text-white border-2 border-black  text-black transition-all ease-out group-hover:-translate-y-1 group-hover:-translate-x-[5px]">
        {title}
      </button>
    </Link>
  )
}

export const Button2 = ({ link, title, className, shadowClass }) => {
  return (
    <a
      className={`group lg:w-[201px] md:w-[190px] sm:w-[300px] md:mt-0 -mt-5 text-center   ${className}`}
      href={link}
      target='_blank'
      rel="noreferrer"
    >
      <div
        className={`relative ${shadowClass} md:top-16 top-14 z-0 md:h-[59px] h-[49px] md:w-full w-[170px] border-2 border-black bg-transparent text-transparent opacity-0 group-hover:opacity-100`}
      >
        {title}
      </div>
      <button className="z-10 md:text-xl text-base font-amiri font-bold md:h-[59px] h-[49px] md:w-full w-[170px] bg-white group-hover:bg-black group-hover:text-white border-2 border-black  text-black transition-all ease-out group-hover:-translate-y-1 group-hover:-translate-x-[5px]">
        {title}
      </button>
    </a>
  )
}

