import { Swiper, SwiperSlide } from 'swiper/react'
import Project from './Project'
import Arrow from './Arrow'
import React from 'react'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import 'swiper/css'

const SwiperProjcts = ({ projects }) => {
  const swiperRef = React.useRef()
  const [isBeginning, setIsBeginning] = React.useState(true)
  const [isEnd, setIsEnd] = React.useState(false)
  const [slidesPerView, setSlidesPerView] = React.useState(3)
  const [spaceBetween, setSpaceBetween] = React.useState(-30)

  React.useEffect(() => {
    const updateSlidesPerView = () => {
      if (window.innerWidth >= 1024) {
        setSlidesPerView(3)
        setSpaceBetween(-30)
      } else {
        setSlidesPerView(1)
        setSpaceBetween(30)
      }
    }

    window.addEventListener('resize', updateSlidesPerView)
    updateSlidesPerView()

    return () => {
      window.removeEventListener('resize', updateSlidesPerView)
    }
  }, [])

  const handleSlideChange = () => {
    const swiper = swiperRef.current
    setIsBeginning(swiper.isBeginning)
    setIsEnd(swiper.isEnd)
  }

  return (
    <div className="flex relative flex-row items-center justify-center ">
      <Arrow
        onClick={() => swiperRef.current?.slidePrev()}
        arrow={<AiOutlineLeft className="md:text-3xl text-xl" />}
        disabled={isBeginning}
      />
      <Swiper
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper
        }}
        onSlideChange={handleSlideChange}
        spaceBetween={spaceBetween}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        className="flex flex-row justify-between"
        slidesPerView={slidesPerView}
      >
        {projects.map((project) => (
          <SwiperSlide key={project.id}>
            <div className="flex justify-center 2xl:-space-x-15 space-x-0 items-center">
              <Project {...project} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <Arrow
        onClick={() => swiperRef.current?.slideNext()}
        arrow={<AiOutlineRight className="md:text-3xl text-xl" />}
        disabled={isEnd}
      />
    </div>
  )
}

export default SwiperProjcts
