import Socials from '../components/Socials'
import { socialsSet } from '../assets/Data/socialsSet'

const Contact = () => {
  return (
    <section
      id="contact"
      className="flex flex-col space-y-8 pt-[132px] pb-[220.22px] "
    >
      <h1 className="font-abril lg:text-[56px] md:text-[46px] md:leading-[76px]  text-3xl text-[#242424]">
        <span className="tagLine">Get in touch</span> with me <br /> through
        this
      </h1>
      <Socials socialsContact={socialsSet} />
    </section>
  )
}
export default Contact
