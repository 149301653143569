import {Button1} from './Button.js'
const TagLine = () => {
  return (
    <div>
      <h1 className="xl:text-[70px] lg:text-[56px] md:text-[46px] text-3xl  font-abril lg:leading-[94px] md:leading-[70px] leading-[40px]">
        Hi! I’m <span className="tagLine">Fiqri</span>, passionate working as a{' '}
        Software <span className="tagLine">Engineer</span>
      </h1>
      <Button1
        title="see my work"
        link="works"
        className={'mx-auto flex flex-col items-center justify-center'}
      />
    </div>
  )
}

export default TagLine
