const Arrow = ({ arrow, onClick, disabled }) => {
  const cursorClass = disabled
    ? 'cursor-not-allowed'
    : 'cursor-pointer hover:bg-[#03FFC5] hover:text-white hover:border-white'
  return (
    <button
      className={` md:mt-14 md:px-2 px-1 md:h-[46px] h-[36px]  bg-transparent border-2 border-[#8C8C8C] ${cursorClass}`}
      onClick={onClick}
      disabled={disabled}
    >
      {arrow}
    </button>
  )
}

export default Arrow
