const Proove = ({ title1, title2, number }) => {
  return (
    <div className="flex flex-col font-amiri text-right md:space-y-10 space-y-4 items-end">
      <h5 className="lg:text-2xl md:text-xl text-base  text-[#868490]">
        {title1} <span className="block md:mt-2 mt-0">{title2}</span>{' '}
      </h5>
      <h4 className="text-[#242424] lg:text-7xl md:text-5xl text-3xl">
        {number}
      </h4>
    </div>
  )
}

export default Proove
