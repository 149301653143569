import Antasena from '../Images/Antasena.jpg'
import EWeek from '../Images/EWeek.jpg'
import ISE2022 from '../Images/ISE 2022.jpg'
import ISE2021 from '../Images/ISE 2021.jpg'
import Petrolida from '../Images/Petrolida.jpg'
import OrderIn from '../Images/Order In.jpg'
import RaihCita from '../Images/RaihCita.jpg'
import UKM from '../Images/UKMExpo.jpg'
import JSB from '../Images/JSB.jpg'
import Bioota from '../Images/Bioota.jpg'
import Kalma from '../Images/Kalma_Rest API.jpg'

export const workSet = [
  {
    id: 11,
    title: 'Kalma',
    image: Kalma,
    href: 'https://medium.com/@muh.fikri.ananda/kalma-rest-api-342f37b09ce7',
  },
  {
    id: 1,
    title: 'Bioota',
    image: Bioota,
    href: 'https://medium.com/@muh.fikri.ananda/bioota-18a722deae09',
  },
  {
    id: 2,
    title: 'Petrolida 2023',
    image: Petrolida,
    href: 'https://medium.com/@muh.fikri.ananda/petrolida-2023-4da490c1b2ec',
  },
  {
    id: 3,
    title: 'JSB Agency',
    image: JSB,
    href: 'https://medium.com/@muh.fikri.ananda/jsb-agency-f33a1e281886',
  },
  {
    id: 4,
    title: 'UKM Expo 2022',
    image: UKM,
    href: 'https://medium.com/@muh.fikri.ananda/ukm-expo-2022-c72796e6c74c',
  },
  {
    id: 5,
    title: 'Economic Week 2022',
    image: EWeek,
    href: 'https://medium.com/@muh.fikri.ananda/economic-week-unair-2022-16ba45b15f7f',
  },
  {
    id: 6,
    title: 'ISE 2022',
    image: ISE2022,
    href: 'https://medium.com/@muh.fikri.ananda/information-systems-expo-2022-e055e79e3194',
  },
  {
    id: 7,
    title: 'OrderIn Foods Web App',
    image: OrderIn,
    href: 'https://medium.com/@muh.fikri.ananda/order-in-2522259c201e',
  },
  {
    id: 8,
    title: 'Raih Cita Website',
    image: RaihCita,
    href: 'https://medium.com/@muh.fikri.ananda/raih-cita-website-625f71d0567f',
  },
  {
    id: 9,
    title: 'Antasena ITS Website',
    image: Antasena,
    href: 'https://medium.com/@muh.fikri.ananda/antasena-its-team-f86b0d98bbd4',
  },
  {
    id: 10,
    title: 'ISE 2021',
    image: ISE2021,
    href: 'https://medium.com/@muh.fikri.ananda/information-systems-expo-2021-233f0bcd8c4d',
  },
]
