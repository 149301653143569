import MyPic from '../assets/Images/MyPic.png'
import Proove from './Proove'
import { workSet } from '../assets/Data/workSet'
import {Button2} from './Button'

const Biographymd = () => {
  return (
    <div className="md:hidden flex flex-col mt-16">
      <div className="flex justify-between order-2  items-center">
        <section className="flex   flex-col font-amiri items-start justify-start w-full text-left">
          <h4 className="lg:text-3xl md:text-xl mb-3.5 font-normal text-[#868490]">
            Biography
          </h4>
          <p className="w-full text-base font-bold">
            Fresh graduate in Information Systems with experience in projects and professional work in Software Engineering.
          </p>
          <div className="flex flex-row justify-between w-full">
            <Button2
              link={'https://drive.google.com/file/d/1sbe0VaRb9EI8XihRzo-op0D64G3SNHaJ/view?usp=sharing'}
              title={'check my resume'}
              className={''}
              shadowClass={''}
            />
            <section className="flex flex-col order-last justify-center items-end">
              <Proove title1={'Years'} title2={'of Experience'} number={2} />
              <Proove title1={'Projects'} title2={'Done'} number={String(workSet.length)} />
            </section>
          </div>
        </section>
      </div>
      <section className="w-[300px] flex justify-center mb-5 mx-auto order-1 m-0 transition-all duration-300 transform hover:-translate-y-2">
        <img alt="Pic of Me" src={MyPic} />
      </section>
    </div>
  )
}

export default Biographymd
