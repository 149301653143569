import React from '../Images/React.png'
import Python from '../Images/python.png'
import JavaScript from '../Images/Js.png'
import TypeScript from '../Images/Ts.png'
import Java from '../Images/java.png'
import Laravel from '../Images/laravel.png'
import Kotlin from '../Images/kotlin.png'
import Android from '../Images/androidStudio.png'
import Tailwind from '../Images/tailwind.png'
import Postgre from '../Images/postgre.png'
import Django from '../Images/django.png'
import Next from '../Images/next (2).png'
import NodeJS from '../Images/nodeJS.png'
import PowerBI from '../Images/powerBI.png'
import Jetpack from '../Images/jetpackCompose.png'
import Angular from '../Images/Angular.png'

export const skillsSet = [
  {
    id: 1,
    title: 'JavaScript',
    image: <img className="w-[104px] h-[134px]" src={JavaScript} alt="JS" />,
  },
  {
    id: 2,
    title: 'TypeScript',
    image: <img className="w-[106px] h-[101px]" src={TypeScript} alt="Ts" />,
  },
  {
    id: 3,
    title: 'React JS',
    image: <img className="w-[130.3px] h-[127px]" src={React} alt="React" />,
  },
  {
    id: 4,
    title: 'Next JS',
    image: <img className="w-[126.11px] h-[127px]" src={Next} alt="Next" />,
  },
  {
    id: 5,
    title: 'Node JS',
    image: <img className="w-[130px] h-[79.73px]" src={NodeJS} alt="Node" />,
  },
  {
    id: 6,
    title: 'Angular',
    image: <img className='w-[130px] h-[127px]' src={Angular} alt="Angular JS" />
  },
  {
    id: 7,
    title: 'Python',
    image: <img className="w-[106px] h-[101.32px]" src={Python} alt="Python" />,
  },
  {
    id: 8,
    title: 'Django',
    image: <img className="w-[95px] h-[121px]" src={Django} alt="Django" />,
  },
  {
    id: 9,
    title: 'Laravel',
    image: (
      <img className="w-[113px] h-[117.52px]" src={Laravel} alt="Laravel" />
    ),
  },
  {
    id: 10,
    title: 'Postgre SQL',
    image: (
      <img className="w-[100px] h-[103.33px]" src={Postgre} alt="Postgre" />
    ),
  },
  {
    id: 11,
    title: 'Tailwind CSS',
    image: (
      <img className="w-[127px] h-[139px]" src={Tailwind} alt="Tailwind" />
    ),
  },
  {
    id: 12,
    title: 'Jetpack Compose',
    image: <img className="w-[140px] h-[134px]" src={Jetpack} alt="Kotlin" />,
  },
  {
    id: 13,
    title: 'Java',
    image: <img className="w-[97px] h-[137px]" src={Java} alt="Java" />,
  },
  {
    id: 14,
    title: 'Kotlin',
    image: <img className="w-[100px] h-[100px]" src={Kotlin} alt="Kotlin" />,
  },
  {
    id: 15,
    title: 'Android Studio',
    image: (
      <img className="w-[109.45px] h-[118px]" src={Android} alt="Android" />
    ),
  },
  {
    id: 16,
    title: 'Power BI',
    image: <img className="w-[122px] h-[122px]" src={PowerBI} alt="Power BI" />,
  },
]
