import { Swiper, SwiperSlide } from 'swiper/react'
import Skill from './Skill'
import Arrow from './Arrow'
import React from 'react'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import 'swiper/css'

const SwiperSkills = ({ skills }) => {
  const swiperRef = React.useRef()
  const [isBeginning, setIsBeginning] = React.useState(true)
  const [isEnd, setIsEnd] = React.useState(false)
  const [slidesPerView, setSlidesPerView] = React.useState(3)

  React.useEffect(() => {
    const updateSlidesPerView = () => {
      if (window.innerWidth > 1024) {
        setSlidesPerView(5)
      } else if (window.innerWidth >= 768) {
        setSlidesPerView(3)
      } else {
        setSlidesPerView(1)
      }
    }

    window.addEventListener('resize', updateSlidesPerView)
    updateSlidesPerView()

    return () => {
      window.removeEventListener('resize', updateSlidesPerView)
    }
  }, [])

  const handleSlideChange = () => {
    const swiper = swiperRef.current
    setIsBeginning(swiper.isBeginning)
    setIsEnd(swiper.isEnd)
  }
  return (
    <div className="flex relative  z-30 flex-row items-center justify-between ">
      <Arrow
        onClick={() => swiperRef.current?.slidePrev()}
        arrow={<AiOutlineLeft className="md:text-3xl text-xl" />}
        disabled={isBeginning}
      />
      <Swiper
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper
        }}
        onSlideChange={handleSlideChange}
        spaceBetween={0}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        className="flex space-x-[42px] flex-row justify-center"
        slidesPerView={slidesPerView}
      >
        {skills.map((skill) => (
          <SwiperSlide key={skill.id}>
            <div className="flex justify-center items-center">
              <Skill {...skill} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <Arrow
        onClick={() => swiperRef.current?.slideNext()}
        arrow={<AiOutlineRight className="md:text-3xl text-xl" />}
        disabled={isEnd}
      />
    </div>
  )
}

export default SwiperSkills
