import TagLine from '../components/TagLine'
import Biography from '../components/Biography'
import Biographymd from '../components/Biographymd'

const About = () => {
  return (
    <section
      id="about"
      className="pt-48 pb-[121.27px] 2xl:px-40 xl:px-32 lg:px-[50px] md:px-10 px-4 "
    >
      <TagLine />
      <Biography />
      <Biographymd />
    </section>
  )
}

export default About
