import Frame from '../assets/Images/frameAsset.png'

const Project = ({ image, title, href }) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className="flex flex-col space-y-6 md:pt-[87px] pt-[20px]  justify-center items-start "
    >
      <div className="relative 2xl:w-[361px] xl:w-[311px] lg:w-[220px] md:w-[400px] w-[250px]">
        <img
          className="absolute 2xl:block hidden z-30 right-0 -top-6 2xl:w-[101px]"
          alt="pic of Frame"
          src={Frame}
        />
        <div className="group relative 2xl:w-[339.25px] xl:w-[311px] lg:w-[220px] z-10  overflow-hidden ">
          <img
            className="2xl:w-[339.25px] xl:h-[254.44px] lg:h-[220px] md:h-[260px] h-[220px] ease-in-out duration-500 group-hover:scale-125"
            alt={title}
            src={image}
          />
          <div className="absolute z-30 bg-black 2xl:w-[339.25px] xl:h-[254.44px] opacity-50 transition-opacity duration-500 group-hover:opacity-100" />
        </div>
      </div>
      <p className="text-2xl text-start font-amiri text-[#242424]">{title}</p>
    </a>
  )
}

export default Project
