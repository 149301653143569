import {
  IconButton,
  MobileNav,
  Navbar,
  Typography,
} from '@material-tailwind/react'
import * as React from 'react'
import { Link } from 'react-scroll'
import Circle from '../assets/Images/circle.png'

const links = [
  { href: 'about', label: 'who i am' },
  { href: 'skills', label: 'my skills' },
  { href: 'works', label: 'my projects' },
  { href: 'contact', label: 'get in touch' },
]

const NavList = () => {
  return (
    <ul className="mb-[75px] z-50 mt-[87px] flex w-full  flex-col  space-y-10 md:mb-0 md:mt-0 md:flex-row md:items-center md:gap-10 md:hover:gap-10 md:space-y-0">
      {links.map(({ href, label }) => {
        return (
          <Typography
            className="flex flex-col group space-y-3 justify-center items-center"
            key={`${href}${label}`}
          >
            <Link
              smooth
              spy={true}
              to={href}
              offset={-50}
              className={` text-xl z-50 cursor-pointer font-amiri font-bold nav text-black `}
              activeClass="active"
            >
              {label}
            </Link>
          </Typography>
        )
      })}
    </ul>
  )
}

export default function Header() {
  const [openNav, setOpenNav] = React.useState(false)

  React.useEffect(() => {
    window.addEventListener(
      'resize',
      () => window.innerWidth >= 960 && setOpenNav(false)
    )
  }, [])

  return (
    <Navbar
      className={`fixed border-2 border-b-black -top-10 z-50  mx-auto  bg-white backdrop-filter ${
        !openNav ? '' : 'h-[571px]'
      }   py-2  px-4 backdrop-blur md:px-[60px]  xl:px-[80px]  2xl:px-[112px]`}
    >
      <div className="text-blue-gray-900 container mx-auto flex items-center justify-between">
        <Typography
          as="h1"
          variant="small"
          className="mr-4 mt-10 py-1.5 font-normal"
        >
          <h1 className="font-amiri  text-black md:text-[32px] text-2xl font-bold">
            M
            <span className="inline">
              <img
                className="inline mt-2 w-[8px] ml-0.5 h-[8px]"
                src={Circle}
                alt="green cirlce"
              />
            </span>{' '}
            Fiqri
          </h1>
        </Typography>
        <div className="mt-10 hidden md:block">
          <NavList />
        </div>
        <IconButton
          variant="text"
          className="ml-auto mt-3 h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent md:hidden"
          ripple={false}
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              className="h-6 w-6"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          )}
        </IconButton>
      </div>
      <MobileNav
        open={openNav}
        className={`text-center md:hidden block  bg-opacity-80 backdrop-filter md:backdrop-filter-none	`}
      >
        <NavList />
      </MobileNav>
    </Navbar>
  )
}
