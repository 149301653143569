const Socials = ({ socialsContact }) => {
  return (
    <div className="flex flex-row justify-center items-center space-x-7">
      {socialsContact.map((contact) => (
        <a
          className="md:text-5xl text-4xl text-[#848484] hover:text-[#03FFC5] transition-all duration-300 transform hover:-translate-y-2"
          href={contact.href}
          target="_blank"
          rel="noreferrer"
        >
          {contact.icon}
        </a>
      ))}
    </div>
  )
}

export default Socials
