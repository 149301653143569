import SwiperProjcts from '../components/SwiperProjects'
import { Button2 } from '../components/Button'

const Works = ({ projects }) => {
  return (
    <section
      id="works"
      className="2xl:px-[84px] md:px-[50px] pt-24 mb-[123.7px] px-4 pb-0"
    >
      <h1 className="font-abril z-10 lg:text-[56px] md:text-[46px] text-3xl text-[#242424]">
        My Projects
      </h1>
      <div className="mx-auto">
        <SwiperProjcts projects={projects} />
        <Button2 className={'mx-auto -mt-4 flex flex-col items-center justify-center'} title={"See PDF Version"} link={'https://drive.google.com/file/d/11xbE_xd8e3xv_R1GHM0uLffDdqXWu8ql/view?usp=sharing'} />
      </div>
    </section>
  )
}

export default Works
