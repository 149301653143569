import Header from './section/Header'
import About from './section/About'
import Advantages from './section/Advantages'
import { skillsSet } from './assets/Data/skillSet'
import { workSet } from './assets/Data/workSet'
import Works from './section/Works'
import Contact from './section/Contact'

function App() {
  return (
    <div className="App">
      <Header />
      <About />
      <div className="2xl:px-40 md:px-28 px-4  ">
        <hr className="bg-black h-0.5" />
      </div>
      <Advantages skillsSet={skillsSet} />
      <div className="2xl:px-40 md:px-28 px-4  ">
        <hr className="bg-black h-0.5" />
      </div>
      <Works projects={workSet} />
      <div className="2xl:px-40 md:px-28 px-4  ">
        <hr className="bg-black h-0.5" />
      </div>
      <Contact />
    </div>
  )
}

export default App
