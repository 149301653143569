const Skill = ({ image, title }) => {
  return (
    <div className="flex flex-col space-y-[6px] md:pt-[87px] pt-[20px] z-40 items-center justify-center">
      <div className="lg:w-[203px] md:w-[153px] w-[203px] grayscale hover:grayscale-0 lg:h-[279.96px] md:h-[229.96px] h-[279.96px] rounded-full flex justify-center items-center  bg-transparent hover:border-2 hover:border-[#03FFC5] transition-all duration-300 transform hover:-translate-y-2">
        <section className="flex justify-center items-center lg:w-[182px] md:w-[132px] w-[182px] lg:h-[251.32px] md:h-[201.32px] h-[251.32px] bg-[#EAF4F2] rounded-full">
          {image}
        </section>
      </div>
      <p className="text-2xl text-[#242424] font-amiri">{title}</p>
    </div>
  )
}

export default Skill
