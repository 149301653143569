import MyPic from '../assets/Images/MyPic.png'
import Proove from './Proove'
import { workSet } from '../assets/Data/workSet'
import {Button2} from './Button'

const Biography = () => {
  return (
    <div className="md:flex hidden justify-between items-center mt-[140px]">
      <section className="flex flex-col font-amiri items-start justify-start w-[222px] text-left">
        <h4 className="lg:text-3xl md:text-xl mb-3.5 font-normal text-[#868490]">
          Biography
        </h4>
        <p className="lg:text-2xl md:text-lg font-bold lg:leading-[180%]">
          Fresh graduate in Information Systems with experience in projects and professional work in Software Engineering.
        </p>
        <Button2
          link={'https://drive.google.com/file/d/1sbe0VaRb9EI8XihRzo-op0D64G3SNHaJ/view?usp=sharing'}
          title={'check my resume'}
          className={'flex flex-col items-start justify-start'}
          shadowClass={''}
        />
      </section>
      <section className="lg:w-[441px] md:w-[300px] w-full xl:ml-[114px] lg:ml-[50px] md:ml-0  xl:mr-[204px] lg:mr-[70px] md:mr-[40px] transition-all duration-300 transform hover:-translate-y-2">
        <img alt="Pic of Me" src={MyPic} />
      </section>
      <section className="flex flex-col items-end space-y-9">
        <Proove title1={'Years'} title2={'of Experience'} number={2} />
        <Proove title1={'Projects'} title2={'Done'} number={String(workSet.length)} />
      </section>
    </div>
  )
}

export default Biography
