import SwiperSkills from '../components/SwiperSkills'

const Advantages = ({ skillsSet }) => {
  return (
    <section
      className="flex pt-[89px] pb-[189px] flex-col 2xl:px-[84px] md:px-[50px] px-4"
      id="skills"
    >
      <h1 className="font-abril  lg:text-[56px] md:text-[46px] text-3xl text-[#242424]">
        My Skills
      </h1>
      <SwiperSkills skills={skillsSet} />
    </section>
  )
}

export default Advantages;